export default {
  items (exploitationId, exploitationName) {
    return [
      {
        title: true,
        name: `${exploitationName}`,
        class: '',
        wrapper: {
          element: '',
          attributes: {}
        }
      },
      {
        name: `Vue d'ensemble`,
        url: `/exploitation/${exploitationId}/dashboard`,
        icon: 'icon-chart',
      },
      {
        name: 'Personnel',
        url: `/exploitation/${exploitationId}/staff`,
        icon: 'icon-people',
        children: [
          {
            name: 'Utilisateurs',
            url: `/exploitation/${exploitationId}/staff/users`,
            icon: 'fa fa-user ml-3'
          },
          {
            name: 'Employés',
            url: `/exploitation/${exploitationId}/staff/employees`,
            icon: 'fa fa-briefcase ml-3'
          },
         /* {
            name: 'Travaux',
            url: `/exploitation/${exploitationId}/staff/tasks`,
            icon: 'fa fa-gavel ml-3'
          },
          {
            name: 'Alertes & Notifs.',
            url: `/exploitation/${exploitationId}/staff/alerts`,
            icon: 'fa fa-bell-o ml-3'
          }*/
        ]
      },
      {
        name: 'Parcelles',
        icon: 'icon-layers',
        url: `/exploitation/${exploitationId}/parcels`,
        children: [
          {
            name: 'Nouvelle parcelle',
            url: `/exploitation/${exploitationId}/parcels/create`,
            icon: 'fa fa-plus ml-3'
          },
          {
            name: 'Liste des parcelles',
            url: `/exploitation/${exploitationId}/parcels/list`,
            icon: 'fa fa-th-large ml-3'
          },
          // {
          //   name: 'Cultures',
          //   url: `/exploitation/${exploitationId}/parcels/cultures/list`,
          //   icon: 'fa fa-leaf ml-3'
          // },
        ]
      },
      {
        name: 'Cultures',
        icon: 'fa fa-leaf',
        url: `/exploitation/${exploitationId}/cultures`,
        children: [
          {
            name: 'Liste de cultures ',
            url: `/exploitation/${exploitationId}/cultures/pialist`,
            icon: 'fa fa-cubes ml-3'
          },
          {
            name: "Mes cultures",
            url: `/exploitation/${exploitationId}/cultures/ownlist`,
            icon: 'fa fa-heart ml-3'
          },
          {
            name: 'Nouvelle culture',
            url: `/exploitation/${exploitationId}/cultures/create`,
            icon: 'fa fa-plus ml-3'
          },
          
         
          {
            name: "gestion cultures",
            url: `/exploitation/${exploitationId}/cultures/manage`,
            icon: 'fa fa-cog ml-3'
          },
        ]
      },
      {
        name: 'Productions',
        icon: 'fa fa-product-hunt',
        url: `/exploitation/${exploitationId}/productions`,
        children: [
          {
            name: 'Nouvelle Production',
            url: `/exploitation/${exploitationId}/production/create`,
            icon: 'fa fa-plus ml-2'
          },
          {
            name: 'Liste des productions',
            url: `/exploitation/${exploitationId}/productions/list`,
            icon: 'fa fa-th-large ml-2'
          },
          {
            name: 'Récoltes',
            url: `/exploitation/${exploitationId}/production/harvests/list`,
            icon: 'fa fa-shopping-cart ml-2'
          }
        ]
      },
      {
        name: 'Commerce',
        icon: 'fa fa-truck',
        url: `/exploitation/${exploitationId}/commerce`,
        children: [
          {
            name: 'Produits',
            url: `/exploitation/${exploitationId}/products/list`,
            icon: 'fa fa-plus ml-3',
          },
          {
            name: 'Ventes',
            url: `/exploitation/${exploitationId}/sales/list`,
            icon: 'fa fa-balance-scale ml-3'
          },
        ]
      },
      {
        name: 'Comptabilité',
        url: `/exploitation/${exploitationId}/accounting`,
        icon: 'icon-wallet',
        children: [
          {
            name: 'Entrées',
            url: `/exploitation/${exploitationId}/accounting/capitalEntries/list`,
            icon: 'fa fa-home ml-3'
          },
          {
            name: 'Dépenses',
            url: `/exploitation/${exploitationId}/accounting/spents/list`,
            icon: 'fa fa-wrench ml-3'
          },
          {
            name: 'Bilan',
            url: `/exploitation/${exploitationId}/accounting/resume`,
            icon: 'fa fa-envira ml-3'
          },
        ]
      },
      {
        name: 'Gestion',
        url: `/exploitation/${exploitationId}/management`,
        icon: 'icon-equalizer',
        children: [
          {
            name: 'Entrepôts',
            url: `/exploitation/${exploitationId}/management/warehouses/list`,
            icon: 'fa fa-home ml-3'
          },
          {
            name: 'Matériel',
            url: `/exploitation/${exploitationId}/management/equipments/list`,
            icon: 'fa fa-wrench ml-3'
          },
          {
            name: 'Intrants',
            url: `/exploitation/${exploitationId}/management/inputs/list`,
            icon: 'fa fa-envira ml-3'
          },
         /* {
            name: 'Produits',
            url: `/exploitation/${exploitationId}/management/products/list`,
            icon: 'fa fa-inbox ml-3',
            attributes: { disabled: true }
          },*/
        ]
      },
      /*{
        name: 'Recommandations',
        url: `/exploitation/${exploitationId}/rec`,
        icon: 'icon-bulb',
        badge: {
          variant: 'success',
          text: ''
        },
        attributes: { disabled: true }
      },
      {
        name: 'Paramètres',
        icon: 'icon-settings',
        url: `/exploitation/${exploitationId}/settings`,
      }*/
    ]
  }
}
