<template>
  <div class="app-body">
    <AppSidebar fixed>
      <SidebarHeader/>
      <SidebarForm/>
      <SidebarNav :navItems="navItems"></SidebarNav>
      <SidebarFooter/>
      <SidebarMinimizer/>
    </AppSidebar>
    <main class="main">
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
      <div class="container-fluid">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
  import {Storage} from "../helpers"
  import nav from '@/_nav'
  import {
    Sidebar as AppSidebar,
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNav,
    Breadcrumb
  } from '@coreui/vue'

  export default {
    name: "ExploitationContainer",
    components: {
      AppSidebar,
      SidebarHeader,
      SidebarForm,
      SidebarNav,
      SidebarFooter,
      SidebarMinimizer,
      Breadcrumb
    },
    created() {
      this.$emit('showMenuButton')
    },
    destroyed() {
      this.$emit('hideMenuButton')
      document.body.className = document.body.className.replace(/sidebar[\-a-z]*-show/, '');
    },
    computed: {
      name() {
        return this.$route.name
      },
      breadcrumbItems() {
        return this.$route.matched
          .filter(route => route.meta && route.meta.breadcrumb)
          .map((route, index, routes) => ({
            text: route.meta.breadcrumb.text ? route.meta.breadcrumb.text : route.name,
            to: route.meta.breadcrumb.route ? route.meta.breadcrumb.route : route,
            active: index === routes.length - 1
          }))
      },
      navItems() {
        const {exploitationId, exploitationName} = this.$store.getters
        return nav.items(exploitationId, exploitationName)
      }
    }
  }
</script>

<style scoped>
  .breadcrumb-item.active {
    color: black;
  }
</style>
